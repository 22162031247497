import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form"; // Import Modal from react-bootstrap
import Button from "react-bootstrap/Button";
import { submitUserGuess } from "../../features/gameSlice";

const GuessInput = () => {
  const placeholder = "Enter your guess";
  const [guess, setGuess] = useState("");
  const dispatch = useDispatch();

  const { isCorrect, currentGuess, failedGuesses } = useSelector(
    (state) => state.game
  );

  const handleChange = (e) => {
    setGuess(e.target.value);
  };

  // Inside your component
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitUserGuess(guess));
    setGuess("");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} data-bs-theme="light">
        <Form.Group controlId="formGuessInput">
          <Form.Label>Attempt {currentGuess}/5</Form.Label>
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={guess}
            onChange={handleChange}
          />
        </Form.Group>
        <Button type="submit">Submit Guess</Button>
      </Form>
      <div className="failed-guesses">
        <h3>Failed Guesses:</h3>
        <ul>
          {failedGuesses.map((guess, index) => (
            <li key={index}>{guess}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GuessInput;
