import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "./backButton.css";

export const BackButton = () => {
    let navigate = useNavigate();

    return (
        <button className="backButton" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#7c27f2" }} /> 
        </button>
    );
};

export default BackButton;
