import React from "react";
import Navigation from "./Navigation";

const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <header className="App-header">{children}</header>
    </div>
  );
};

export default Layout;
