import React from "react";
import PageContent from "./PageContent";
import ContactForm from "./ContactForm";
import "./ContactForm.css";
import logo from './Logo.jpg';
import { Link } from 'react-router-dom';
import BackButton from "components/common/backButton";

const ContactUs = () => {
  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
      <BackButton />
      </div>

      <h1>Contact Us</h1>
      <p>Questions? Feedback? Get in touch with us.</p>
      <ContactForm />
    </PageContent>
  );
};

export default ContactUs;
