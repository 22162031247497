import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css"; // Fix: Add missing quotes around the import path
import store from "./redux/store";
import Layout from "./components/common/Layout";
import { fetchLevels, setNavigateToHome } from "./features/gameSlice"; // Import the action
import AppRouter from "./AppRouter";
import PageContent from "./components/pages/PageContent";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const navigateToHome = useSelector((state) => state.game.navigateToHome);

  useEffect(() => {
    // List of media types to fetch
    const mediaTypes = ["games", "tv_shows", "movies"];

    // Dispatch fetchLevels for each media type
    mediaTypes.forEach((mediaType) => {
      dispatch(fetchLevels(mediaType));
    });
  }, [dispatch]);

  useEffect(() => {
    if (navigateToHome) {
      dispatch(setNavigateToHome(false));
      navigate("/"); // Reset the flag
    }
  }, [navigateToHome, navigate, dispatch]);

  return (
    <Provider store={store}>
      <Layout>
        <AppRouter />
        <PageContent>{/* Main content goes here */}</PageContent>
      </Layout>
    </Provider>
  );
}

export default App;
