import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import AboutUs from "./components/pages/AboutUs";
import Home from "./components/pages/Home";
import Game from "./components/game/Game";
import HowToPlay from "./components/pages/HowToPlay";
import Leaderboard from "./components/pages/Leaderboard";
import Login from "./components/pages/Login";
import Signup from "./components/pages/SignUp";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/game" element={<Game />} />
      <Route path="/how-to-play" element={<HowToPlay />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
};

export default AppRouter;
