import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useDispatch } from "react-redux";
import {
  setMediaType,
  fetchLevels,
  setGameCompleted,
} from "../../features/gameSlice"; // Import the action
import "./Home.css";
import logo from "./Logo.jpg";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChangeMediaType = (mediaType) => {
    dispatch(setMediaType(mediaType));
    dispatch(fetchLevels(mediaType));
    dispatch(setGameCompleted(false));
    navigate("/game"); // Navigate to the home page
  };

  return (
    <div className="home-container">
      {" "}
      <img src={logo} alt="GuessQuest Logo" className="logo" />
      <h1>Welcome to GuessQuest!</h1>
      <p>Pick Your Passion:</p>
      <div className="d-grid gap-1">
        <button
          className="btn btn-primary"
          onClick={() => handleChangeMediaType("movies")}
        >
          Movies
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleChangeMediaType("tv_shows")}
        >
          TV Shows
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleChangeMediaType("games")}
        >
          Games
        </button>
      </div>
    </div>
  );
};

export default Home;
