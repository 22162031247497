import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import axios from "axios";
import { setModalState, setVictoryAnimation } from "./uiSlice";

const isGuessCorrect = (guess, correctGuess) => {
  return guess.toLowerCase() === correctGuess.toLowerCase();
};

export const fetchLevels = createAsyncThunk(
  "game/fetchLevels",
  async (mediaType, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.winterrose.uk/api/game/media/${mediaType}`
      );
      console.log(response.data.data);
      return response.data.data; // Adjust according to the API response structure
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// Async thunk for submitting a user guess
export const submitUserGuess = createAsyncThunk(
  "game/submitUserGuess",
  async (guess, { getState, dispatch, rejectWithValue }) => {
    try {
      const { game } = getState();
      const currentLevelData =
        game.levels[game.currentMediaType][game.currentLevel];

      if (isGuessCorrect(guess, currentLevelData.correctGuess)) {
        handleCorrectGuess(dispatch, game, currentLevelData);
      } else {
        handleIncorrectGuess(dispatch, game, guess);
      }
    } catch (error) {
      console.error(`Error in submitUserGuess: ${error.message}`);
      return rejectWithValue(error.message);
    }
  }
);

// Handling correct guess
const handleCorrectGuess = (dispatch, game, currentLevelData) => {
  dispatch(
    setModalState({
      show: true,
      message: "Correct! You've guessed the right answer.",
    })
  );
};

// Handling incorrect guess
const handleIncorrectGuess = (dispatch, game, guess) => {
  dispatch(failedGuess(guess));
  if (game.currentGuess >= 5) {
    dispatch(
      setModalState({
        show: true,
        message: `Sorry, that's not correct. The correct answer was: ${
          game.levels[game.currentMediaType][game.currentLevel].correctGuess
        }.`,
      })
    );
  }
};

const gameSlice = createSlice({
  name: "game",
  initialState: {
    currentLevel: 0, // Zero Indexed
    status: "idle", // idle, loading, succeeded, failed
    error: null,
    navigateToHome: false, // Error code or message
    currentMediaType: "movies",
    gameCompleted: false,
    currentGuess: 1,
    failedGuesses: [],
    isCorrect: false,
    currentImageLink: "",
    answer: "",
    levels: {
      games: [],
      tv_shows: [],
      movies: [],
    },
  },
  reducers: {
    setNavigateToHome: (state, action) => {
      state.navigateToHome = action.payload;
    },
    setGameCompleted: (state, action) => {
      state.gameCompleted = action.payload;
    },
    setMediaType: (state, action) => {
      state.currentMediaType = action.payload;
      state.currentLevel = 0;
      state.currentGuess = 1; // Reset level when changing media type
    },


    nextLevel: (state) => {
      const totalLevels = state.levels[state.currentMediaType].length;
      if (state.currentLevel < totalLevels - 1) {
        // Increment currentLevel if not last level
        state.currentLevel += 1;
      } else {
        // User has completed the last level
        state.gameCompleted = true; // You might want to add a new state property for this
      }
      // Reset other state variables
      state.currentGuess = 1;
      state.isCorrect = false;
      state.failedGuesses = [];
    },
    failedGuess: (state, action) => {
      if (state.currentGuess < 5) {
        state.failedGuesses.push(action.payload);
        state.currentGuess += 1;
      }
      state.isCorrect = false;
    },
    resetFailedGuesses: (state) => {
      state.failedGuesses = [];
      state.currentGuess = 1;
      state.isCorrect = false;
    },
  },
  extraReducers: {
    [submitUserGuess.pending]: (state) => {
      state.status = "loading";
    },
    [submitUserGuess.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [submitUserGuess.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchLevels.pending]: (state) => {
      state.status = "loading";
    },
    [fetchLevels.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // Update the levels state with the fetched data
      state.levels[action.meta.arg] = action.payload;
    },
    [fetchLevels.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // other extraReducers...
  },
});

export const {
  submitGuess,
  nextLevel,
  setMediaType,
  preloadData,
  failedGuess,
  resetFailedGuesses,
  setGameCompleted,
  setNavigateToHome,
} = gameSlice.actions;

export default gameSlice.reducer;
