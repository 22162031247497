import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://api.winterrose.uk/api/users/login",
        credentials
      );
      // Assuming the response contains the user data and/or auth token
      return response.data;
    } catch (error) {
      // Handle error case and return a rejection
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://api.winterrose.uk/api/users/register",
        userData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    error: null,
    status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.status = "loading";
    },
    [registerUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
    },
    [registerUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [loginUser.pending]: (state) => {
      state.status = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.meta.arg.username; // Update the state with user data
    },
    [loginUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload; // Update the error state
    },
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
