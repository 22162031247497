import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageContent from "./PageContent";
import { registerUser } from "./../../features/userSlice"; // Adjust the path as needed
import "./SignUp.css";
import logo from "./Logo.jpg";
import BackButton from "components/common/backButton";

const Signup = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { user, error } = useSelector((state) => state.user);
  const [displayError, setDisplayError] = useState("");

  useEffect(() => {
    if (error) {
      setDisplayError(error.error);
    }
  }, [error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting signup form...");
    dispatch(registerUser({ username, password }));
  };

  if (user) {
    return (
      <PageContent>
        <h1>Thank you for signing up!</h1>
        <Link to="/">Go to Home</Link>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
        <BackButton />
      </div>

      <h1>Create an account</h1>
      <form className="signup-form" onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Sign up</button>
      </form>
      {displayError && <p className="error-message">{displayError}</p>}
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </PageContent>
  );
};

export default Signup;
