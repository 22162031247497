import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from "./../../features/userSlice";
import PageContent from "./PageContent";
import "./Login.css";
import logo from "./Logo.jpg";
import BackButton from "components/common/backButton";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { error, user } = useSelector((state) => state.user);

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }));
  };

  if (user) {
    return (
      <PageContent>
        <h1>Thank you for logging in!</h1>
        <Link to="/">Go to Home</Link>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
        <BackButton />
      </div>

      <h1>Welcome back</h1>

      {error && <div className="error-message">{error.error}</div>}

      <form className="login-form" onSubmit={handleLogin}>
        <label>
          Username:
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p>
    </PageContent>
  );
};

export default Login;
