import React from "react";
import "./StaticPages.css";
import PageContent from "./PageContent";
import logo from './Logo.jpg';
import { Link } from 'react-router-dom';
import BackButton from "components/common/backButton";

const AboutUs = () => {
  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
      <BackButton />
      </div>
      
      <div className="text-container">
      <h1>About Us</h1>
      <div>
      <p className="text-container">
      <span className="bold-text">GuessQuest is made by Hannah, Alicia, Michelle and Jac.</span><br /> 
      We are coders on the CFG degree bootcamp, which we began 16 weeks ago, fresh-faced and wide-eyed...<br /> 
      It's been a wild ride! By now we are all pretty exhausted, slightly broken, gaunt and grey.<br /> 
      We've lost some of our innocence. We've gained a lot of coding knowledge.<br />
      We've got a few battle scars (yes Redux, we're talking about you).<br />
      But this is our final group project and - by goddess! - we've given it everything we have to give.<br />
      We are all proud to be geeks and we wanted GuessQuest to be a nerd-friendly, female-led, diverse and inclusive place - where people like us can play!<br />
      <span className="bold-text">We hope you love it.</span><br />
      <br />
      What else? Well...<br />
      <span className="bold-text">Hannah</span> loves playing Dungeons and Dragons:<br /> "It's a great way to engage with stories and friends, exercising creativity and strategy, and it's a fun escape from the routine."<br />
      <span className="bold-text">Alicia</span> loves singing:<br /> "Performing with my band brings me joy and excitement. Creating music is not just a hobby for me; it's a form of expression and a way to connect with others."<br />
      <span className="bold-text">Michelle</span> loves art:<br /> "I love being creative. Whether it is photography, painting or pondering on art in a gallery, it is a way to be in the moment and bask in the wonder of it all!"<br />
      <span className="bold-text">Jac</span> loves creative writing:<br /> "It makes me feel connected to my imagination; I feel inspired and it brings me joy." 
      <br />
      <br />
      That's enough about us...<br />
      <Link to="/" className="play-link">
      Go <span className="bold-text">PLAY!</span>
      </Link>
      </p>
      </div>
      </div>
    </PageContent>
  );
};

export default AboutUs;
