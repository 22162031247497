// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import gameReducer from "../features/gameSlice";
import uiReducer from "../features/uiSlice";
import userReducer from "../features/userSlice";

export const store = configureStore({
  reducer: {
    game: gameReducer,
    ui: uiReducer,
    user: userReducer,
    // other reducers...
  },
});

export default store;
