import React, { useState, useEffect } from "react";
import PageContent from "./PageContent";
import "./Leaderboard.css";
import logo from "./Logo.jpg";
import { Link } from "react-router-dom";
import BackButton from "components/common/backButton";

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    fetch("https://api.winterrose.uk/api/game/leaderboard")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.leaderboard) {
          setLeaderboardData(data.leaderboard);
        }
      })
      .catch((error) => {
        console.error("Error fetching leaderboard data:", error);
      });
  }, []);

  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
      <BackButton />
      </div>

      <div className="leaderboard-container">
        <h1>Leaderboard</h1>

        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>High Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((entry, index) => (
              <tr
                key={index}
                className={
                  index % 3 === 0
                    ? "purple"
                    : index % 3 === 1
                    ? "grey"
                    : "white"
                }
              >
                <td>{entry.username}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageContent>
  );
};

export default Leaderboard;
