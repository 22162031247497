import React from "react";
import "./StaticPages.css";
import PageContent from "./PageContent";
import logo from './Logo.jpg';
import { Link } from 'react-router-dom';
import BackButton from "components/common/backButton";

const HowToPlay = () => {
  return (
    <PageContent>
      <div className="image-container">
        <Link to="/">
          <img src={logo} alt="GuessQuest Logo" />
        </Link>
      </div>

      <div>
      <BackButton />
      </div>
      
  <div className="text-container">
  <h1>How to Play</h1>
  <div>
      <span className="bold-text">Welcome to GuessQuest, where geekspertise leads to glory!</span><br />
      <br />
      Playing is a breeze: 
      
      <ul className="text-list">
        <li><span className="bold-text">Pick Your Passion:</span> choose your favourite category: TV shows, games, or movies.</li>
        <li><span className="bold-text">Tap to Begin:</span> hit the button, and voila! The first image from a mysterious source will appear.</li>
        <li><span className="bold-text">Trust Your Instincts:</span> take your best guess on where that image hails from. No worries if you're stumped – you can always skip to the next image!</li>
        <li><span className="bold-text">5 Images, 5 Guesses:</span> there are five images and five chances for each mystery - get it right on image 1 or 2 to prove your expertise.</li>
        <li><span className="bold-text">Keep Climbing:</span> missed the mark? No problem! Move on to the next mystery and give it another shot.</li>
        <li><span className="bold-text">Leaderboard Triumph:</span> may the force be with you! Aim for your podium finish!</li>
      </ul>
      Get ready for a nerdventure like no other – <span className="bold-text">your GuessQuest awaits!</span>
  </div>
</div>

    </PageContent>
  );
};

export default HowToPlay;
