import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "./../../features/userSlice";
import "./Navigation.css";

const Navigation = () => {
  // State to control the opening and closing of the menu
  const [menuOpen, setMenuOpen] = useState(false);
  // References to the menu and toggle button for click handling
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);
  // Hook to dispatch actions to Redux store
  const dispatch = useDispatch();
  // Accessing user state from the Redux store
  const user = useSelector((state) => state.user.user);

  // Function to toggle the menu's open state
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Effect to handle clicks outside the menu to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        toggleButtonRef.current &&
        !menuRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    // Attaching and cleaning up the outside click listener
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  // Function to handle logout action
  const handleLogout = () => {
    dispatch(logout());
  };

  // Array of menu items, dynamically including Logout/Login based on user authentication
  const menuItems = [
    { path: "/", label: "Home" },
    { path: "/how-to-play", label: "How to Play" },
    { path: "/about", label: "About Us" },
    { path: "/leaderboard", label: "Leaderboard" },
    { path: "/contact", label: "Contact Us" },
    ...(user
      ? [{ path: "/", label: "Logout", action: handleLogout }]
      : [{ path: "/login", label: "Login" }]),
  ];

  return (
    <div className={`navigation ${menuOpen ? "open" : ""}`} role="navigation">
      {/* Toggle button to open/close the navigation menu */}
      <button
        className="menu-toggle"
        onClick={toggleMenu}
        ref={toggleButtonRef}
      >
        ☰
      </button>
      {/* Navigation menu */}
      <div className="menu" ref={menuRef}>
        <ul>
          {/* Mapping menuItems to render Link components */}
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.path} onClick={item.action || toggleMenu}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
