import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GuessInput from "./GuessInput";
import GameImage from "./GameImage";
import "./Game.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import GameModal from "./GameModal";
import PageContent from "components/pages/PageContent";
import logo from "../pages/Logo.jpg";
import BackButton from "components/common/backButton";

// Component to display when the game is finished
const FinishScreen = () => {
  // Using useSelector to access the user state from Redux store
  const user = useSelector((state) => state.user.user);

  return (
    <PageContent>
      <h1>Game Complete! Thank you for playing.</h1>
      {/* Conditionally rendering the user's name if it exists in the state */}
      {user && (
        <>
          <h2>Player: {user}</h2>
        </>
      )}
      {/* Link to restart the game */}
      <Link to="/">Try Again?</Link>
    </PageContent>
  );
};

const Game = () => {
  // Accessing gameCompleted state from Redux store to check if the game is complete
  const gameComplete = useSelector((state) => state.game.gameCompleted);

  return (
    <>
      {/* Conditional rendering based on the game completion status */}
      {gameComplete ? (
        <FinishScreen /> // Render FinishScreen if game is complete
      ) : (
        // Render the game interface if the game is not complete
        <Container maxWidth="md">
          <Box>
            {/* Link to home with the game's logo */}
            <Link to="/">
              <img src={logo} alt="GuessQuest Logo" className="logo" />
            </Link>

            {/* Back button to navigate to the previous screen */}
            <div>
              <BackButton />
            </div>

            {/* Components for the game's main functionality */}
            <GameImage className="GameImage" role="GameImage" />
            <GuessInput role="GuessInput" />
            <GameModal />
          </Box>
        </Container>
      )}
    </>
  );
};

export default Game;
