import React from "react";
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import "./GameImage.css";

const GameImage = () => {
  // Destructure the required properties directly from the state
  const { currentMediaType, currentLevel, levels } = useSelector(
    (state) => state.game
  );

  // Safely access the images array
  const currentImages = levels[currentMediaType]?.[currentLevel]?.images || [];

  return (
    <div className="game-image-container">
      <Carousel interval={null} slide={false}>
        {currentImages.map((imgSrc) => (
          <Carousel.Item key={imgSrc}>
            <img
              className="d-block w-100"
              src={imgSrc}
              alt={`Slide showing game image`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default GameImage;
