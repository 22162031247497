// src/features/ui/uiSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    showModal: false,
    modalMessage: "",
    showVictoryAnimation: false,
  },
  reducers: {
    setModalState: (state, action) => {
      state.showModal = action.payload.show;
      state.modalMessage = action.payload.message;
    },
    setVictoryAnimation: (state, action) => {
      state.showVictoryAnimation = action.payload;
    },
  },
});

export const { setModalState, setVictoryAnimation } = uiSlice.actions;

export default uiSlice.reducer;
