import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { setModalState } from "./../../features/uiSlice"; // Adjust the import path as needed
import { nextLevel } from "./../../features/gameSlice"; // Import the action to load the next level

function GameModal() {
  const dispatch = useDispatch();
  const { showModal, modalMessage } = useSelector((state) => state.ui);

  const handleClose = () => {
    dispatch(setModalState({ show: false, message: "" }));
  };

  const handleNextLevel = () => {
    dispatch(nextLevel()); // Dispatch the action to load the next level
    handleClose(); // Close the modal
  };

  return (
    <Modal show={showModal} onHide={handleNextLevel}>
      <Modal.Header closeButton>
        <Modal.Title>Game Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleNextLevel}>
          Next Level
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GameModal;
